import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import { QuoteContext } from "../../Context/QuoteContext"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import Icon from "../../components/Icon/Icon"
import OutlinedButton from "../../components/GetQuote/Fields/Buttons/OutlinedButton"
import { Email, validateEmail } from "../../components/GetQuote/Fields/fields"
import { fieldNames } from "../../utils/enums"
import {
  updateField,
  fetchingFailed,
  formResetError,
} from "../../Context/Actions"
import BeatLoader from "react-spinners/BeatLoader"
import { DispatchRequest } from "../../API/QuoteService"
import { ENDPOINT } from "../../API/endpoints"

const ConfirmPage = ({ location }) => {
  const { state, dispatch } = useContext(QuoteContext)
  const theme = useTheme()
  const [savedEmail, setSavedEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  if (!state.hasQuote && !state.hasLoadedQuote) {
    if (typeof window === "undefined") {
      return null
    }

    // If reloaded go back to start
    if (location.search !== "") {
      navigate(`/s?${new URLSearchParams(location.search).toString()}`)
    } else {
      navigate("/")
    }

    return null
  }

  const handleSubmit = e => {
    e.preventDefault()

    const isValid = validateEmail(state.fields.email.fieldValue)

    if (isValid.valid) {
      setIsLoading(true)

      dispatch(formResetError())

      DispatchRequest(ENDPOINT.ADD_EMAIL, {
        inputData: {
          guid: state.quote.result.guid,
          email: state.fields.email.fieldValue,
        },
      }).then(response => {
        setIsLoading(false)

        if (response.status !== 200) {
          dispatch(fetchingFailed("Systemet svarar inte."))
          return
        }

        if (response.data.status.code > 0) {
          dispatch(
            fetchingFailed(
              response.data.status.message ||
                "Vi har tekniska problem, prova igen om en stund."
            )
          )

          return
        }

        // All is good
        setSavedEmail(true)
      })
    } else {
      dispatch(
        updateField({
          name: fieldNames.EMAIL,
          value: state.fields.email.fieldValue,
          valid: false,
          message: isValid.message,
          dirty: true,
        })
      )
    }
  }

  return (
    <Layout>
      <SEO title="evoli - Din betalning är registrerad!" noIndex={true} />
      <QuoteContainer>
        <QuoteHeroContent>
          <Icon
            name="thumbs-up"
            bgColor={theme.colors.CardContainer.iconBackground}
            iconColor={theme.colors.CardContainer.iconColor}
            iconSize="25"
          />
          <h1>Din betalning är registrerad!</h1>

          <p style={{ marginBottom: "20px", maxWidth: "280px" }}>
            Försäkrad produkt: {state.quote.result.brand},{" "}
            {state.quote.result.itemName}.
            <br />
            Försäkringsbrevet skickas till din mail.
          </p>

          {state.quote.result.policyLetter && (
            <OutlinedButton
              text="Öppna ditt försäkringsbrev"
              type="button"
              onClick={() => window.open(state.quote.result.policyLetter)}
            />
          )}

          <HelpSection
            style={{ display: "block", marginTop: "20px", fontWeight: "400" }}
          >
            Om du behöver hjälp eller har frågor om din försäkring{" "}
            <SupportLink
              href="https://support.evoli.se"
              rel="noopener noreferrer"
              target="blank"
            >
              <span style={{ fontWeight: "400" }}>klicka här</span>
            </SupportLink>
            .
          </HelpSection>

          {state.quote.result.askEmail && !savedEmail && (
            <div>
              <FormContainer onSubmit={handleSubmit}>
                <Email />

                <Button>
                  {!isLoading ? (
                    "Spara"
                  ) : (
                    <BeatLoader size={10} color={"#c4ffe1"} loading={true} />
                  )}
                </Button>

                <ErrorMessage>{state.error}</ErrorMessage>
                <p>
                  Lägg till din e-post för att få information om din försäkring
                  mejlad till dig.
                </p>
              </FormContainer>
            </div>
          )}

          {savedEmail && (
            <ConfirmContainer>
              <ConfirmMessage>
                <Icon name="flag" iconColor="#027d61" iconSize="20" size="20" />
                <span>
                  Tack! Du behöver verifiera din e-post för att vi ska kunna
                  mejla information till dig.
                </span>
              </ConfirmMessage>
            </ConfirmContainer>
          )}

          <InformationContainer>
            <Information>
              <strong>Trygghet i världsklass!</strong>
              <p>
                Vi är licensierade av Finansinspektionen och bakom oss har vi
                ett par av världens största globala försäkringsbolag, vilket
                garanterar alla utbetalningar till dig som kund.
              </p>
            </Information>
            <Information>
              <strong>100% ångerrätt, hela tiden!</strong>
              <p>
                Hos oss kan du alltid säga upp din försäkring precis när du vill
                och din försäkring avslutas direkt. Du kan med andra ord byta
                till ett annat försäkringsbolag om du hittar ett billigare,
                eller om du inte är nöjd.
              </p>
            </Information>
          </InformationContainer>
        </QuoteHeroContent>
      </QuoteContainer>
    </Layout>
  )
}

export default ConfirmPage

const ErrorMessage = styled.div`
  color: #ff4984;
  font-size: 11px;
  max-width: 206px;
  margin-top: 2px;
`

const ConfirmContainer = styled.div`
  margin: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormContainer = styled.form`
  margin: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 22px 2px rgba(170, 169, 173, 0.2);
  padding: 20px;
  max-width: 300px;

  @media (max-width: 320px) {
    font-size: 12px;
  }

  p {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
  }
`

const ConfirmMessage = styled.div`
  background: #c4ffe1;
  padding: 15px;
  text-align: left;
  border-radius: 8px;
  color: #004535;
  font-size: 12px;
  max-width: 300px;
  display: flex;
  align-items: center;

  @media (max-width: 320px) {
    font-size: 12px;
  }

  span {
    margin-left: 15px;
  }
`

const UpperCase = styled.span`
  text-transform: uppercase;
`
const LowerCase = styled.span`
  text-transform: lowercase;
`

const SupportLink = styled.a`
  color: #5558ed;
`

const Button = styled.button`
  width: 206px;
  background: linear-gradient(
    180deg,
    rgba(107, 60, 231, 1) 5%,
    rgba(87, 55, 213, 1) 100%
  );
  color: #ffffff;
  border-radius: 0.3rem;
  font-size: 16px;
  height: 48px;
  border: 0;

  @media (min-width: 376px) {
    margin-top: 20px;
  }

  :disabled {
    opacity: 0.3;

    :hover {
      cursor: not-allowed;
    }
  }

  :hover {
    background: linear-gradient(
      180deg,
      rgba(107, 60, 231, 0.9) 5%,
      rgba(87, 55, 213, 0.9) 100%
    );
    cursor: pointer;
  }
`

const InformationContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`

const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 415px;
  margin-bottom: 30px;
  padding: 0 20px;
  text-align: center;

  @media (max-width: 414px) {
    max-width: 300px;
    padding: 0;
  }

  @media (max-width: 374px) {
    padding: 0 10px;
    text-align: left;
  }

  strong {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5558ea;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;

    color: #63638f;
  }
`

export const QuoteContainer = styled.section`
  background: ${props => (props.bgColor ? props.bgColor : "none")};
  width: 100%;
  padding: 50px 40px 80px 40px;
  min-height: 570px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 50px 40px 80px 40px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 600px) and (min-width: 414px) {
    padding: 30px 20px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - 46px);
  }

  @media (max-width: 375px) {
    padding: 30px 16px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    min-height: calc(100vh - 46px);
  }

  @media (max-width: 374px) {
    /* padding: 0px 15px; */
    margin: 0;
    text-align: left;
    flex-direction: column;
  }
`

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;
    max-width: 12ch;

    span {
      display: block;
      color: #5558ea;
      margin-top: 10px;
    }
  }

  h2 {
    margin: 0;
    margin-top: 25px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #5558ea;
  }

  p {
    margin-top: 6px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    /* max-width: 280px; */
  }

  ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 10px;
    max-width: 280px;
  }

  li {
    color: #5558ea;
    font-size: 12px;
    font-weight: 500;
    display: flex;

    align-items: center;

    span {
      margin-right: 8px;
      background: ${props => (props.darkMode ? "#5558ea" : "#c4ffe1")};
      color: ${props => (props.darkMode ? "#c4ffe1" : "#5558ea")};
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
const HelpSection = styled.p`
  max-width: 230px !important;
`
